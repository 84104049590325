import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from 'next/link';

import NoSeo from '@/components/shared/custom/no-seo-component';
import { Rutas } from '@/constants/rutas';

const Page = () => {
    const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <section>
            <NoSeo title="Error | Analítica Fantasy" />
            <Box
                component="main"
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    py: '80px',
                }}
            >
                <Container maxWidth="lg">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 6,
                        }}
                    >
                        <Box
                            alt="Not found"
                            component="img"
                            src={`${process.env.NEXT_PUBLIC_IMAGES}/assets/errors/error-404.png?width=100`}
                            sx={{
                                height: 'auto',
                                maxWidth: '100%',
                                width: 100,
                            }}
                        />
                    </Box>
                    <Typography align="center" variant={mdUp ? 'h4' : 'h4'}>
                        Ups! Parece que esta página no existe.
                    </Typography>
                    <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
                        Has intentado alguna ruta sospechosa o has llegado aquí por error. Sea cual sea el
                        caso, intenta usar la navegación.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 6,
                            }}
                        >
                            <Link href={Rutas.INICIO} passHref>
                                <Button variant="contained">Ir a Inicio</Button>
                            </Link>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 6,
                            }}
                        >
                            <Link href={Rutas.MERCADO} passHref>
                                <Button variant="contained" color="secondary">
                                    Ir al mercado
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </section>
    );
};

export default Page;
